var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.quizInfo)?_c('div',{staticClass:"test"},[_c('div',{staticClass:"test__title"},[_c('span',[_vm._v(_vm._s(_vm.$t("testResults"))+": "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.quizInfo.title)}})])]),_c('span',{staticClass:"test__result-text"},[_vm._v(_vm._s(_vm.quizResultInfo.data.stats.score.text))]),_c('ul',_vm._l((_vm.quizResultInfo.data.answers),function(item,idx){return _c('li',[(item.question.type === 'multiple')?[_c('p',{staticClass:"question",domProps:{"innerHTML":_vm._s(item.question.title)}}),_c('ul',{staticClass:"answers"},_vm._l((item.answerInfo),function(option){return _c('li',{class:{
                            correct: option.isCorrect && option.isSelected,
                            incorrect: !option.isCorrect && option.isSelected,
                        }},[_c('p',{domProps:{"innerHTML":_vm._s(option.content)}}),_c('label',[_c('input',{attrs:{"type":"checkbox","id":option.id},domProps:{"value":option.id,"checked":option.isSelected}}),_c('span')])])}),0),_c('span',{staticClass:"total"},[_vm._v(_vm._s(item.total))])]:_vm._e(),(item.question.type === 'single')?[_c('p',{staticClass:"question",domProps:{"innerHTML":_vm._s(item.question.title)}}),_c('ul',{staticClass:"answers"},_vm._l((item.answerInfo),function(option){return _c('li',{class:{
                            correct: option.isCorrect && option.isSelected,
                            incorrect: !option.isCorrect && option.isSelected,
                        }},[_c('p',{domProps:{"innerHTML":_vm._s(option.content)}}),_c('label',[_c('input',{attrs:{"type":"checkbox","id":option.id},domProps:{"value":option.id,"checked":option.isSelected}}),_c('span')])])}),0),_c('span',{staticClass:"total"},[_vm._v(_vm._s(item.total))])]:_vm._e()],2)}),0),_c('div',{staticClass:"test__button"},[_c('main-button',{attrs:{"outline":true,"label":_vm.$t('returnToYourAccount')},on:{"change-event":_vm.goToProfile}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }